/**
 * Retrieves the specified attribute value from the given element as a number.
 * If the attribute value is not a number, it returns the default value instead.
 *
 * @param {Element} element - The HTML element.
 * @param {string} attribute - The name of the attribute to retrieve.
 * @param {number} [defaultValue=0] - The default value to return if the attribute value is not a number.
 * @returns {number} - The attribute value as a number, or the default value if the attribute value is not a number.
 */
export function getAttributeAsNumber(element, attribute, defaultValue = 0) {
  const value = parseFloat(element.getAttribute(attribute));
  return isNaN(value) ? defaultValue : value;
}

/**
 * Retrieves the value of an attribute from the given element as a boolean. If the attribute
 * does not exist or is a falsy value, the method will return the provided default value.
 *
 * @param {Element} element - The element from which to retrieve the attribute value.
 * @param {string} attribute - The name of the attribute to retrieve.
 * @param {boolean} defaultValue - The value to return if the attribute does not exist or is falsy.
 * @returns {boolean} - The retrieved attribute value as a boolean, or the default value.
 */
export function getAttributeAsBoolean(element, attribute, defaultValue = false) {
  const value = element.getAttribute(attribute);
  return value ? JSON.parse(value) : defaultValue;
}

/**
 * Constructs breakpoints for responsive behavior. Object based on given data.
 *
 * @param {string} data - in the format "width:view:space:initial". Each breakpoint value is separated by a comma.
 * @param {object} breakpoints - The breakpoints object to update.
 * @param {boolean} isLoopSlides - Indicates if loop slides option is enabled. (Default: true)
 * @return {void}
 */
export function constructBreakpoints(data, breakpoints, isLoopSlides = true) {
  data.split(",").forEach(function (value) {
    const parts = value.split(":");
    const width = Number(parts[0]);
    const view = !isNaN(parseFloat(parts[1])) ? Number(parts[1]) : parts[1]; // view = number or 'auto'
    const space = Number(parts[2]);
    const initial = Number(parts[3]);
    let attributes = {};

    if (view) {
      attributes.slidesPerView = view;
    }
    if (space || space === 0) {
      attributes.spaceBetween = space;
    }
    if (isLoopSlides && (initial || initial === 0)) {
      attributes.initialSlide = initial;
    }

    breakpoints[width] = attributes;
  });
}

/**
 * Sets the settings for the next button of a swiper container.
 * Position the next button to the right of the last visible slide,
 * centered vertically in relation to the image
 *
 * @param {Object} swiper - The Swiper instance.
 * @param {HTMLElement} swiperContainer - The container element of the Swiper.
 * @param {HTMLElement} btn - The next button element.
 */
export function nextBtnSettings(swiper, swiperContainer, btn) {
  const img = swiperContainer.querySelector(".swiper-slide img");
  const swiperSlide = swiperContainer.querySelector(".swiper-slide");
  const slidesPerView = swiper.params.slidesPerView;
  const totalSlides = swiper.slides.length;

  if (slidesPerView >= totalSlides) {
    btn.style.display = "none";
  } else {
    btn.style.removeProperty("display");
  }

  const firstSlideMarginRight =
    swiper.slides && swiper.slides[0] ? swiper.slides[0].style.getPropertyValue("margin-right") : 0;
  const slideMarginRight = parseFloat(
    swiper.slides && swiper.slides[0] && firstSlideMarginRight !== "" ? firstSlideMarginRight : 0,
  );
  const slideParentPaddingLeft = parseFloat(
    swiper.slides && swiper.slides[0] ? swiper.slides[0].offsetParent.offsetLeft : 0,
  );
  const slideParentPaddingTop = parseFloat(
    swiper.slides && swiper.slides[0] ? swiper.slides[0].offsetParent.offsetTop : 0,
  );
  const isDecimalSlidesPerView = slidesPerView !== Math.floor(slidesPerView);
  const slidePerViewSize =
    ((swiper && swiper.slides[0] ? swiper.slides[0].clientWidth : 0) + slideMarginRight) * Math.floor(slidesPerView) -
    slideMarginRight / 2 +
    slideParentPaddingLeft -
    (!isDecimalSlidesPerView ? slideMarginRight / 2 : 0);
  const imgTranslateY = () => {
    if (!img || !swiperSlide) {
      return 0;
    }
    return img.clientHeight || swiperSlide.clientHeight;
  };

  // Add global position X, Y of nextBtn
  if (slidesPerView !== "auto") {
    btn.style.top = "0";
    btn.style.right = "auto";
    btn.style.transform =
      "translate(" +
      (slidePerViewSize - btn.clientWidth / 2) +
      "px, " +
      (imgTranslateY() / 2 - btn.clientHeight / 2 + slideParentPaddingTop) +
      "px)"; // translate(X, Y)
  } else {
    btn.style.removeProperty("top");
    btn.style.removeProperty("right");
    btn.style.removeProperty("transform");
  }

  // Change position X of nextBtn by devices
  const swiperContainerParent = swiperContainer.parentElement;

  if (!isDecimalSlidesPerView && window.innerWidth <= swiperContainerParent.clientWidth + btn.clientWidth / 2) {
    btn.style.left = -btn.clientWidth + "px";
  } else {
    btn.style.left = "auto";
  }

  // Change size of nextBtn by devices
  if (window.innerWidth >= 1024) {
    btn.classList.remove("is-small");
  } else {
    btn.classList.add("is-small");
  }
}

/**
 * Adjusts the slide size when the number of slides is insufficient
 *
 * @param {Object} swiper - The swiper instance
 */
export function adjustSlideSizeWhenInsufficient(swiper) {
  const slidesNumber = swiper.wrapperEl.children.length;
  const slidesPerView = swiper.params.slidesPerView;

  for (let i = 0; i < slidesNumber; i++) {
    // Forcing maxWidth if number of slide is smaller than view
    if (slidesNumber < slidesPerView) {
      if (slidesPerView > 2) {
        swiper.slides[i].style.maxWidth = "33.33333%";
      } else {
        swiper.slides[i].style.maxWidth = "50%";
      }
    } else {
      // Remove maxWidth
      swiper.slides[i].style.removeProperty("max-width");
    }
  }
}

// Adjust format of slides with specific class :
// js-slideSquare
// js-slideLandscape
/**
 * Adjusts the slide height and aspect ratio based on the swiper size and slide type
 * with specific class :
 * js-slideSquare
 * js-slideLandscape
 *
 * @param {Object} swiper - The swiper instance.
 * @returns {null}
 */
export function adjustSlideMultiFormat(swiper) {
  if (!swiper.el.classList.contains("swiper--mixedSize")) {
    return null;
  }

  const heightMap = {
    1504: "480px",
    1024: "400px",
    640: "480px",
    default: "auto",
  };

  /**
   * Returns the height of a slide based on the given swiper size.
   *
   * @param {number} swiperSize - The width of the swiper in pixels.
   * @return {number} - The height of the slide in pixels.
   */
  function getSlideHeight(swiperSize) {
    if (swiperSize >= 1504) return heightMap["1504"];
    if (swiperSize >= 1024) return heightMap["1024"];
    if (swiperSize >= 640) return heightMap["640"];
    return heightMap["default"];
  }

  for (let i = 0; i < swiper.slides.length; i++) {
    const slide = swiper.slides[i];
    const childSlide = slide.children[0].children; // = .ci-CardOverlay-media
    const isSquare = slide.classList.contains("js-slideSquare");
    const isLandscape = slide.classList.contains("js-slideLandscape");

    slide.children[0].style.minHeight = "100%";

    for (const child of childSlide) {
      child.style.width = "100%";

      const aspectRatioMap = {
        square: "1 / 1",
        landscape: "7 / 4",
        default: "2 / 3",
      };

      let slideType = "default";
      if (isSquare) slideType = "square";
      if (isLandscape) slideType = "landscape";

      const aspectRatio = aspectRatioMap[slideType];
      child.style.aspectRatio = aspectRatio;
      slide.style.aspectRatio = aspectRatio;
      slide.style.height = getSlideHeight(swiper.size);
    }
  }
}
